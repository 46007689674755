export enum ConnectionStatus {
  DISCONNECTED,
  CONNECTING,
  CONNECTED
}

interface ISerialOptions extends SerialOptions {}
interface ISerialPortRequestOptions extends SerialPortRequestOptions {}

export type IConnectionConfig = SerialOptions & SerialPortFilter

export interface IDeviceEvent<TType> {
  type: TType
  data: unknown
}

export interface IConnection {
  serialOptions: ISerialOptions | undefined

  serialPortRequestOptions: ISerialPortRequestOptions | undefined

  status: ConnectionStatus

  open(): Promise<void>

  close(): Promise<void>
}

export abstract class AbstractConnection<TDeviceEventType, TEvent extends IDeviceEvent<TDeviceEventType>> implements IConnection {
  constructor(protected eventListener: (event: TEvent, instance: IConnection) => any) {}

  serialOptions: ISerialOptions | undefined

  serialPortRequestOptions: ISerialPortRequestOptions | undefined

  status: ConnectionStatus = ConnectionStatus.DISCONNECTED

  abstract open(): Promise<void>

  abstract close(): Promise<void>
}
